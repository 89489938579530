import cx from 'classnames'
import classes from './styles.module.scss'
import { useTranslation } from '@patrianna/core-components'

type ValidationAdornmentProps = {
  hasError: boolean
  className?: string
}

export default function ValidationAdornment({ hasError, className }: ValidationAdornmentProps) {
  const t = useTranslation()

  return (
    <div className={cx(classes.icon, className)} aria-label={t('common.validation')}>
      <img
        className={classes.title}
        src={`${process.env.IMG_ORIGIN}/login/${hasError ? 'error' : 'right'}.svg`}
        alt='validation icon'
      />
    </div>
  )
}
