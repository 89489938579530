import { differenceInYears, isFuture, isValid } from 'date-fns'
import type { PasswordPolicyType } from '@patrianna/shared-patrianna-types/store/AppConfigModule'
import { createDate } from '@patrianna/shared-utils/date'
import { isBrowser } from '@patrianna/shared-utils/helpers'
import { ForbiddenUSStates } from 'utils/usStates'

function t(key: string) {
  const [type, field] = key.split('.')

  // @ts-ignore
  return isBrowser() ? window.__FORM_VALIDATION_TRANSLATIONS__?.[type]?.[field] : key
}

export type ValidationType = {
  firstName?: string
  lastName?: string
  first_name?: string
  last_name?: string
  full_name?: string
  email?: string
  password?: string
  confirmPassword?: string
  oldPassword?: string
  amount?: number
  accountHolderName?: string
  abaRoutingNumber?: string
  holderName?: string
  accountNumber?: string
  swiftBic?: string
  bankName?: string
  bankLocation?: string
  address?: string
  address1?: string
  city?: string
  state?: string
  birthDate?: Date
  country?: string
  zip?: string
  month?: string
  year?: string
  day?: string
  fiRouting?: string
  fiAcc?: string
  fiAccType?: string
  phoneNumber?: string
  addr?: string
  postalCode?: string
  stateOrProvince?: string
  street?: string
  contribute?: number
  stateResidency?: string
  // card kyc validation
  kyc_address?: string
  kyc_city?: string
  kyc_state?: string
  kyc_zip?: string
  kyc_country?: string
  kyc_month?: string
  kyc_year?: string
  kyc_day?: string
  kyc_firstName?: string
  kyc_lastName?: string
  kyc_email?: string
}

export type PasswordValidationValuesType = {
  password: string
  email?: string
}

type PasswordValidationPatternsType = {
  [key: string]: {
    validation: (values: PasswordValidationValuesType, limiter?: number | boolean) => boolean
    message: () => string
    show: boolean
  }
}

type PasswordValidationMessagesType = {
  [key: string]: string
}

// eslint-disable-next-line no-control-regex
const REGEX_ASCII = /[^\x00-\xFF]/

export const asciiValidator = (values: Record<string, any>) => (errors: ValidationType) => {
  const fields = [
    'first_name',
    'last_name',
    'full_name',
    'email',
    // for card payment kyc
    'kyc_firstName',
    'kyc_lastName',
    'kyc_email',
  ]

  let newErrors = Object.assign(errors, {})

  Object.keys(values).forEach((key) => {
    let field = null

    if (REGEX_ASCII.test(values[key]) && fields.includes(key)) {
      const exampleChars = values[key]
        .split('')
        .filter((char: any) => REGEX_ASCII.test(char))
        .join('')

      field = `Text entered contains invalid characters (${exampleChars}). Please amend and try again.`
    }

    if (field) {
      newErrors = Object.assign(errors, { [key]: field })
    }
  })

  return newErrors
}

const REGEX_EMAIL_WITHOUT_SPEC_SYMBOL = /^[._%+-]+[A-Z0-9]|([._%+-])\1|[A-Z0-9]+[._%+-]+@/i
const REGEX_EMAIL = /^[A-Z0-9._%+-]+@[A-Z0-9]+[A-Z0-9.-]+[A-Z0-9]+\.[A-Z]{2,4}$/i

export const emailValidator =
  (values: ValidationType, field: keyof ValidationType = 'email') =>
  (errors: ValidationType) => {
    let email = null

    if (!values[field]) {
      email = t('formValidators.required')
    } else if (
      REGEX_EMAIL_WITHOUT_SPEC_SYMBOL.test(values[field] as string) ||
      !REGEX_EMAIL.test(values[field] as string)
    ) {
      // @ts-ignore
      email = t('formValidators.enter_valid_email_address')
    }

    if (email) {
      return Object.assign(errors, { email })
    }

    return errors
  }

export const usPhoneNumberValidator =
  (values: Record<string, any>, fieldName: string) => (errors: Record<string, any>) => {
    let error = null

    if (!values[fieldName]) {
      error = 'Required'
    } else if (!/^\d{10}$/i.test(values[fieldName]) && !process.env.ALLOW_VOIP_NUMBERS) {
      error = '10 digits'
    }

    if (error) {
      return Object.assign(errors, { [fieldName]: error })
    }

    return errors
  }

export const phoneNumberValidator =
  (
    values: Record<string, any>,
    key: keyof ValidationType,
    { length, withSign = true, startWith }: { length: number; withSign?: boolean; startWith?: string }
  ) =>
  (errors: Record<string, any>) => {
    let error = null

    let value = values[key]
    const regExp = new RegExp(`^[+]\\d{${length}}$`, 'i')

    if (!value) {
      error = t('formValidators.required')
    } else if (withSign && !/^\+\d+/i.test(value)) {
      error = t('formValidators.plus_symbol')
    } else if (startWith && !String(value)?.startsWith(startWith)) {
      error = `${t('formValidators.should_contain')} ${startWith}`
    } else if (!regExp.test(value)) {
      error = `${length} ${t('formValidators.digits')}`
    }

    if (error) {
      return Object.assign(errors, { [key]: error })
    }

    return errors
  }

export const otpCodeValidator = (values: Record<string, any>, fieldName: string) => (errors: Record<string, any>) => {
  let error = null

  if (!values[fieldName]) {
    error = 'Required'
  }

  if (!/^[0-9]{6,6}$/i.test(values[fieldName])) {
    // @ts-ignore
    error = '6 numbers'
  }

  if (error) {
    return Object.assign(errors, { [fieldName]: error })
  }

  return errors
}

export const firstNameValidator = (values: ValidationType) => (errors: ValidationType) => {
  let firstName = null

  if (!values.firstName) {
    firstName = 'Required field'
  } else if (values.firstName.length < 2) {
    firstName = t('formValidators.min_letters')
  } else if (/\s{2,}/.test(values.firstName)) {
    firstName = t('formValidators.max_space')
  }

  if (firstName) {
    return Object.assign(errors, { firstName })
  }

  return errors
}

export const lastNameValidator = (values: ValidationType) => (errors: ValidationType) => {
  let lastName = null

  if (!values.lastName) {
    lastName = 'Required field'
  } else if (values.lastName.length < 2) {
    lastName = t('formValidators.min_letters')
  } else if (/\s{2,}/.test(values.lastName)) {
    lastName = t('formValidators.max_space')
  }

  if (lastName) {
    return Object.assign(errors, { lastName })
  }

  return errors
}

export const validationPatterns: PasswordValidationPatternsType = {
  required: {
    validation: ({ password }) => !password.length,
    message: () => t('formValidators.required'),
    show: false,
  },
  min: {
    validation: ({ password }, minValue: number) => password.length < minValue,
    message: () => t('formValidators.min_8_sybmols'),
    show: true,
  },
  lowerCase: {
    validation: ({ password }) => !/.*[a-z].*/.test(password),
    message: () => t('formValidators.lowercase_letter'),
    show: true,
  },
  upperCase: {
    validation: ({ password }) => !/.*[A-Z].*/.test(password),
    message: () => t('formValidators.uppercase_letter'),
    show: true,
  },
  special: {
    validation: ({ password }) => !/(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/g.test(password),
    message: () => t('formValidators.special_symbol'),
    show: true,
  },
  digits: {
    validation: ({ password }) => !/\d/.test(password),
    message: () => t('formValidators.one_digit'),
    show: true,
  },
  max: {
    validation: ({ password }, maxValue: number) => password.length > maxValue,
    message: () => t('formValidators.max_length_20_symbols'),
    show: false,
  },
  shouldNotMatchEmail: {
    validation: ({ password, email }) => {
      if (!email) {
        return false
      }

      return email.toLowerCase() === password.toLowerCase()
    },
    message: () => t('formValidators.choose_a_different_password'),
    show: false,
  },
}

export const validationPasswordWithConfig = (
  values: PasswordValidationValuesType,
  config: PasswordPolicyType,
  validationPatternPropertyes: PasswordValidationPatternsType
) => {
  const message: PasswordValidationMessagesType = {}
  if (config) {
    Object.keys(config).forEach((it: keyof PasswordPolicyType) => {
      if (Object.prototype.hasOwnProperty.call(validationPatternPropertyes, 'required')) {
        if (validationPatternPropertyes.required.validation(values)) {
          message.required = validationPatternPropertyes.required.message()
        }
      }
      if (Object.prototype.hasOwnProperty.call(validationPatternPropertyes, it) && config[it]) {
        if (validationPatternPropertyes[it].validation(values, config[it])) {
          message[it] = validationPatternPropertyes[it].message()
        }
      }
    })

    return message
  }

  return message
}

export const requiredValidator = (values: ValidationType, key: keyof ValidationType) => (errors: ValidationType) => {
  let errorMessage = null
  // track length of string
  if (!values[key]?.toString()?.length) {
    errorMessage = 'Required field'
  }

  if (errorMessage) {
    return Object.assign(errors, { [key]: errorMessage })
  }

  return errors
}

export const currentYearValidator = (values: ValidationType, key: keyof ValidationType) => (errors: ValidationType) => {
  let errorMessage = null

  if (Number(values[key]) < new Date().getFullYear()) {
    errorMessage = 'Invalid year'
  }

  if (errorMessage) {
    return Object.assign(errors, { [key]: errorMessage })
  }

  return errors
}

export const checkNumberOfSymbols =
  (values: ValidationType, key: keyof ValidationType, digits: number) => (errors: ValidationType) => {
    let errorMessage = null

    if (values[key]?.toString()?.length !== digits) {
      errorMessage = `You need to enter ${digits} digits`
    }

    if (errorMessage) {
      return Object.assign(errors, { [key]: errorMessage })
    }

    return errors
  }

export const stateValidator =
  (
    values: ValidationType,
    stateField: keyof Pick<ValidationType, 'kyc_state' | 'state'> = 'state',
    countryField: keyof Pick<ValidationType, 'kyc_country' | 'country'> = 'country'
  ) =>
  (errors: ValidationType) => {
    if (!values[stateField]?.toString()?.length && values[countryField]?.toLowerCase() === 'us') {
      return Object.assign(errors, { [stateField]: 'State required' })
    }

    if (values[countryField]?.toLowerCase() === 'us' && ForbiddenUSStates.includes(<string>values[stateField])) {
      return Object.assign(errors, { [stateField]: 'You are not entitled to play in this state' })
    }

    return errors
  }

export const stateResidencyValidator = (values: ValidationType, isUS: boolean) => (errors: ValidationType) => {
  if (!isUS && values.country !== 'US') {
    return errors
  }

  let errorMessage = null
  if (!values.stateResidency?.length) {
    errorMessage = 'State required'
  }

  if (ForbiddenUSStates.includes(<string>values.stateResidency)) {
    errorMessage = 'You are not entitled to play in this state'
  }

  if (errorMessage) {
    return Object.assign(errors, { stateResidency: errorMessage })
  }

  return errors
}

export const achRoutingNumberValidator =
  (values: ValidationType, key: keyof ValidationType) => (errors: ValidationType) => {
    let errorMessage = null
    if (!values[key]?.toString?.()?.length) {
      errorMessage = 'Required field'
    } else if (
      !/^((0[0-9])|(1[0-2])|(2[1-9])|(3[0-2])|(6[1-9])|(7[0-2])|80)([0-9]{7})$/.test(values[key]!.toString())
    ) {
      errorMessage = 'Routing number should be 9 digits'
    }

    if (errorMessage) {
      return Object.assign(errors, { [key]: errorMessage })
    }

    return errors
  }

export const achAccountNumberValidator =
  (values: ValidationType, key: keyof ValidationType, minLength: number, maxLength: number) =>
  (errors: ValidationType) => {
    let errorMessage = null
    if (!values[key]?.toString?.()?.length) {
      errorMessage = 'Required field'
    } else if ((values[key]?.toString?.()?.length || 0) < minLength && /^[0-9\b]+$/.test(values[key]!.toString())) {
      errorMessage = `Routing number should be from ${minLength} to ${maxLength} digits`
    } else if ((values[key]?.toString?.()?.length || 0) > maxLength && /^[0-9\b]+$/.test(values[key]!.toString())) {
      errorMessage = `Routing number should be from ${minLength} to ${maxLength} digits`
    }

    if (errorMessage) {
      return Object.assign(errors, { [key]: errorMessage })
    }

    return errors
  }

export const passwordValidator = (values: ValidationType, config: PasswordPolicyType) => (errors: ValidationType) => {
  const { password = '', email } = values
  const messages: PasswordValidationMessagesType = validationPasswordWithConfig(
    { password, email },
    config,
    validationPatterns
  )

  if (Object.keys(messages).length) {
    return Object.assign(errors, { password: Object.values(messages)[0] })
  }

  return errors
}

export const confirmPasswordValidator =
  (values: ValidationType, config: PasswordPolicyType) => (errors: ValidationType) => {
    const confirmPasswordCheck = validationPasswordWithConfig(
      { password: values.confirmPassword, email: values.email },
      config,
      validationPatterns
    )

    if (Object.keys(confirmPasswordCheck).length) {
      return { ...errors, confirmPassword: Object.values(confirmPasswordCheck)[0] }
    } else if (values.password !== values.confirmPassword) {
      return { ...errors, confirmPassword: t('formValidators.passwords_do_not_match') }
    }

    return errors
  }

export const confirmPassword = (values: ValidationType, compareValue: string) => (errors: ValidationType) => {
  let confirmPasswordCheck = null
  if (!values.confirmPassword) {
    confirmPasswordCheck = t('formValidators.required')
  } else if (values.confirmPassword !== compareValue) {
    confirmPasswordCheck = t('formValidators.passwords_do_not_match')
  }
  if (confirmPasswordCheck) {
    return Object.assign(errors, { confirmPasswordCheck })
  }

  return errors
}

const ERROR_TEXT__INVALID_EXPIRY_DATE = 'Expiry date is invalid'
const ERROR_TEXT__MONTH_OUT_OF_RANGE = 'Expiry month must be between 01 and 12'
const ERROR_TEXT__YEAR_OUT_OF_RANGE = 'Expiry year cannot be in the past'
const ERROR_TEXT__DATE_OUT_OF_RANGE = 'Expiry date cannot be in the past'
const YEAR_OUT_RANGE = 'Invalid year'
const CARD_YEAR_DIFFERENCE = 20

const EXPIRY_DATE_REGEX = /^(\d{2})\/(\d{4}|\d{2})$/
const MONTH_REGEX = /(0[1-9]|1[0-2])/

export const cardYearValidator = (values: ValidationType, key: keyof ValidationType) => (errors: ValidationType) => {
  if (values[key] && differenceInYears(new Date(Number(values[key]), 0, 0), new Date()) >= CARD_YEAR_DIFFERENCE) {
    return Object.assign(errors, { [key]: YEAR_OUT_RANGE })
  }

  return errors
}

export const isExpiryInvalid = (expiryDate: string) => {
  const splitDate = expiryDate.split('/')
  if (!EXPIRY_DATE_REGEX.test(expiryDate)) {
    return ERROR_TEXT__INVALID_EXPIRY_DATE
  }

  const expiryMonth = splitDate[0]
  if (!MONTH_REGEX.test(expiryMonth)) {
    return ERROR_TEXT__MONTH_OUT_OF_RANGE
  }

  const expiryYear = splitDate[1]
  const date = new Date()
  let currentYear = date.getFullYear()
  const currentMonth = date.getMonth() + 1
  currentYear = parseInt(
    // @ts-ignore
    expiryYear.length === 4 ? currentYear : currentYear.toString().substr(-2),
    10
  )
  if (currentYear > parseInt(expiryYear, 10)) {
    return ERROR_TEXT__YEAR_OUT_OF_RANGE
  }

  if (parseInt(expiryYear, 10) === currentYear && parseInt(expiryMonth, 10) < currentMonth) {
    return ERROR_TEXT__DATE_OUT_OF_RANGE
  }

  return false
}

export const withdrawalAmountValidation =
  (values: ValidationType, maxAmount: number, minAmount: number) => (errors: ValidationType) => {
    let amountValidationText = null

    if (!values.amount) {
      return Object.assign(errors, { amount: 'Amount not specified.' })
    }

    if (values.amount < minAmount) {
      amountValidationText = 'You amount less then available minimum amount.'
    } else if (values.amount > maxAmount) {
      amountValidationText = 'You amount more then available maximum amount.'
    }
    if (amountValidationText) {
      return Object.assign(errors, { amount: amountValidationText })
    }

    return errors
  }

export const ageValidation = (values: ValidationType, key: keyof ValidationType) => (errors: ValidationType) => {
  if (!values.birthDate) {
    return Object.assign(errors, { [key]: 'birthDate not specified' })
  }

  if (!isValid(values.birthDate)) {
    return Object.assign(errors, { [key]: 'Invalid date' })
  } else if (isFuture(values.birthDate)) {
    return Object.assign(errors, { [key]: 'Future date not allowed' })
  } else if (differenceInYears(new Date(), values.birthDate) < 18) {
    return Object.assign(errors, { [key]: 'You have to be 18 years or older' })
  }

  return errors
}

export const monthValidator = (values: ValidationType, key: keyof ValidationType) => (errors: ValidationType) => {
  if (!MONTH_REGEX.test(String(values[key]))) {
    return Object.assign(errors, { [key]: 'Month must be valid' })
  }

  return errors
}

type DOBType = ['day' | 'kyc_day', 'month' | 'kyc_month', 'year' | 'kyc_year']
export const birthDateValidation =
  (values: ValidationType, fields: DOBType = ['day', 'month', 'year']) =>
  (errors: ValidationType) => {
    const [day, month, year] = fields
    const birthDate = createDate(values[year]!, values[month]!, values[day]!)
    const minYear = new Date().getFullYear() - 120

    if (
      !values[year]?.toString()?.length ||
      !values[month]?.toString()?.length ||
      !values[day]?.toString()?.length ||
      !isValid(birthDate)
    ) {
      return Object.assign(errors, {
        birthDate: 'Invalid date',
        [day]: !values[day]?.toString()?.length || Number(values[day]) > 31 || Number(values[day]) < 1,
        [month]: !values[month]?.toString()?.length,
        [year]: !values[year]?.toString()?.length,
      })
    } else if (Number(values[day]) > 31 || Number(values[day]) < 1 || birthDate.getDate() !== Number(values[day])) {
      return Object.assign(errors, { birthDate: 'Invalid date', [day]: true })
    } else if (isFuture(birthDate)) {
      return Object.assign(errors, { birthDate: 'Future date not allowed' })
    } else if (differenceInYears(new Date(), birthDate) < 18) {
      return Object.assign(errors, { birthDate: 'You have to be 18 years or older' })
    } else if (Number(values.year) <= minYear) {
      return Object.assign(errors, { birthDate: 'Invalid date', [year]: true })
    }

    return errors
  }

export const contributionAmountValidation =
  (values: ValidationType, maxAmount: number, minAmount: number) => (errors: ValidationType) => {
    const contribute = Number(values.contribute)
    let amountValidationText = null

    if (contribute < minAmount) {
      amountValidationText = `Contribution needs to be at least ${minAmount}`
    } else if (contribute > maxAmount) {
      amountValidationText = `Contribution needs to be no more ${maxAmount}`
    }
    if (amountValidationText) {
      return Object.assign(errors, { contribute: amountValidationText })
    }

    return errors
  }

export const countryValidator = (values: ValidationType, isUS?: boolean) => (errors: ValidationType) => {
  let errorMessage = null

  // country field is required only when MULTI_COUNTRY_SUPPORT: true
  if (!process.env.MULTI_COUNTRY_SUPPORT || isUS) {
    return errors
  }

  if (!values.country) {
    errorMessage = 'Country is required'
  }

  if (errorMessage) {
    return Object.assign(errors, { country: errorMessage })
  }

  return errors
}

export const specialSymbolsValidator =
  (values: ValidationType, key: keyof Omit<ValidationType, 'birthDate' | 'amount'>) => (errors: ValidationType) => {
    let errorMessage = null
    const allowedSymbols = /^[a-zA-Z\s]*$/

    if (!allowedSymbols.test(values[key] as string)) {
      errorMessage = 'Please input the correct value'
    }

    if (errorMessage) {
      return Object.assign(errors, { [key]: errorMessage })
    }

    return errors
  }

export const zipValidation =
  (
    values: ValidationType,
    key: keyof Omit<ValidationType, 'amount' | 'contribute' | 'birthDate'>,
    validateOptions: { minLength: number; maxLength?: number }
  ) =>
  (errors: ValidationType) => {
    let errorMessage = null
    const { minLength, maxLength = 10 } = validateOptions
    const isUs = values['country']?.toLocaleLowerCase?.() === 'us'
    if (isUs) {
      if (!/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(values[key])) {
        errorMessage = 'Please input correct value'
      }
    } else {
      if (values[key]?.toString()?.length < minLength) {
        errorMessage = `You need to enter minimum ${minLength} digits`
      }
      if (values[key]?.toString()?.length > maxLength) {
        errorMessage = `You need to enter less than ${maxLength} digits`
      }
    }
    if (errorMessage) {
      return Object.assign(errors, { [key]: errorMessage })
    }

    return errors
  }
